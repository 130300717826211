import React, { useState, useEffect } from "react"
import dropDownStyles from "./dropDown.module.scss"

import { useMeasure } from "react-use"
import { useSpring, a } from "react-spring"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

function DropDown(props) {
  const defaultHeight = "0px"

  // Manages the open or cloased state of the accordion
  const [open, setOpen] = useState(false)

  // The height of the content inside of the accordion
  const [contentHeight, setContentHeight] = useState(defaultHeight)

  // Gets the height of the element (ref)
  const [ref, { height }] = useMeasure()

  // Animations
  const expand = useSpring({
    height: open ? `${contentHeight}px` : defaultHeight,
  })
  const spin = useSpring({
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
  })

  useEffect(() => {
    //Sets initial height
    setContentHeight(height)

    //Adds resize event listener
    window.addEventListener("resize", setContentHeight(height + 20))

    // Clean-up
    return window.removeEventListener("resize", setContentHeight(height + 20))
  }, [height])

  return (
    <div className={dropDownStyles.section}>
      <button
        className={dropDownStyles.accordion}
        onClick={() => setOpen(!open)}
      >
        <div className={dropDownStyles.accordionContent}>
          <span className={dropDownStyles.title}>{`${props.title} ${
            props.count ? `(${props.count})` : ""
          }`}</span>
          <a.div className={dropDownStyles.icon} style={spin}>
            <FontAwesomeIcon icon={faChevronDown} />
          </a.div>
        </div>
      </button>
      <a.div className={dropDownStyles.content} style={expand}>
        <div className={dropDownStyles.filters} ref={ref}>
          {props.children}
        </div>
      </a.div>
    </div>
  )
}

export default DropDown
