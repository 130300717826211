import React from "react"
import sizeGuideStyles from "./sizeGuide.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"

function SizeGuide({ handleClose, collection }) {
  const womenTable = () => {
    return (
      <table className={sizeGuideStyles.table}>
        <thead>
          <tr className={sizeGuideStyles.row}>
            <th className={sizeGuideStyles.headingCell}>Int</th>
            <th className={sizeGuideStyles.headingCell}>US</th>
            <th className={sizeGuideStyles.headingCell}>UK</th>
            <th className={sizeGuideStyles.headingCell}>EU</th>
          </tr>
        </thead>
        <tbody>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>XXS</td>
            <td className={sizeGuideStyles.cell}>0</td>
            <td className={sizeGuideStyles.cell}>4</td>
            <td className={sizeGuideStyles.cell}>32</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>XS</td>
            <td className={sizeGuideStyles.cell}>2</td>
            <td className={sizeGuideStyles.cell}>6</td>
            <td className={sizeGuideStyles.cell}>34</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>S</td>
            <td className={sizeGuideStyles.cell}>4</td>
            <td className={sizeGuideStyles.cell}>8</td>
            <td className={sizeGuideStyles.cell}>36</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>M</td>
            <td className={sizeGuideStyles.cell}>6</td>
            <td className={sizeGuideStyles.cell}>10</td>
            <td className={sizeGuideStyles.cell}>38</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>L</td>
            <td className={sizeGuideStyles.cell}>8</td>
            <td className={sizeGuideStyles.cell}>12</td>
            <td className={sizeGuideStyles.cell}>40</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>XL</td>
            <td className={sizeGuideStyles.cell}>10</td>
            <td className={sizeGuideStyles.cell}>14</td>
            <td className={sizeGuideStyles.cell}>42</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>XXL</td>
            <td className={sizeGuideStyles.cell}>12</td>
            <td className={sizeGuideStyles.cell}>16</td>
            <td className={sizeGuideStyles.cell}>44</td>
          </tr>
        </tbody>
      </table>
    )
  }

  const maternityTable = () => {
    return (
      <table className={sizeGuideStyles.table}>
        <thead>
          <tr className={sizeGuideStyles.row}>
            <th className={sizeGuideStyles.headingCell}>Int</th>
            <th className={sizeGuideStyles.headingCell}>US</th>
            <th className={sizeGuideStyles.headingCell}>UK</th>
            <th className={sizeGuideStyles.headingCell}>EU</th>
          </tr>
        </thead>
        <tbody>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>XXS</td>
            <td className={sizeGuideStyles.cell}>0</td>
            <td className={sizeGuideStyles.cell}>4</td>
            <td className={sizeGuideStyles.cell}>32</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>XS</td>
            <td className={sizeGuideStyles.cell}>2</td>
            <td className={sizeGuideStyles.cell}>6</td>
            <td className={sizeGuideStyles.cell}>34</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>S</td>
            <td className={sizeGuideStyles.cell}>4</td>
            <td className={sizeGuideStyles.cell}>8</td>
            <td className={sizeGuideStyles.cell}>36</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>M</td>
            <td className={sizeGuideStyles.cell}>6</td>
            <td className={sizeGuideStyles.cell}>10</td>
            <td className={sizeGuideStyles.cell}>38</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>L</td>
            <td className={sizeGuideStyles.cell}>8</td>
            <td className={sizeGuideStyles.cell}>12</td>
            <td className={sizeGuideStyles.cell}>40</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>XL</td>
            <td className={sizeGuideStyles.cell}>10</td>
            <td className={sizeGuideStyles.cell}>14</td>
            <td className={sizeGuideStyles.cell}>42</td>
          </tr>
          <tr className={sizeGuideStyles.row}>
            <td className={sizeGuideStyles.cell}>XXL</td>
            <td className={sizeGuideStyles.cell}>12</td>
            <td className={sizeGuideStyles.cell}>16</td>
            <td className={sizeGuideStyles.cell}>44</td>
          </tr>
        </tbody>
      </table>
    )
  }

  const plusTable = () => {}

  return (
    <div className={sizeGuideStyles.container}>
      <div className={sizeGuideStyles.box}>
        <button onClick={handleClose} className={sizeGuideStyles.close}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
        <h1
          className={sizeGuideStyles.heading}
        >{`${collection} Size Guide`}</h1>
        {collection === "Women"
          ? womenTable()
          : collection === "Maternity"
          ? maternityTable()
          : plusTable}
      </div>
    </div>
  )
}

export default SizeGuide
