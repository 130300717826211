import React, { useState, useEffect, useContext } from "react"
import { graphql, Link } from "gatsby"
import productPageStyles from "./productPage.module.scss"

import Layout from "../../components/layout/layout"
import HELM from "../../components/helmet.js"
import Img from "gatsby-image"
import VisibilitySensor from "react-visibility-sensor"
import { useSpring, animated } from "react-spring"
import { Link as ScrollLink } from "react-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import DropDown from "../../components/dropDown/dropDown"
import FeaturedCatalog from "../../components/featuredCatalog/featuredCatalog"
import sanitizeHtml from "sanitize-html"
import SizeGuide from "../../components/sizeGuide/sizeGuide"
import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "../../utilities/swiperStyles/swiperColor.scss"
import BounceLoader from "react-spinners/BounceLoader"
import StoreContext from "../../utilities/context/storeContext"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import fbTrack from "../../utilities/pixel/fbTrack"

export const productQuery = graphql`
  query($id: String!) {
    shopifyProduct(id: { eq: $id }) {
      title
      descriptionHtml
      images {
        localFile {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
      variants {
        shopifyId
        availableForSale
        compareAtPrice
        price
        sku
        title
        image {
          localFile {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        selectedOptions {
          name
          value
        }
      }
      productType
      tags
      options {
        name
        values
      }
    }
  }
`

function ProductPage({ data, pageContext }) {
  const productData = data.shopifyProduct
  const productCollection = pageContext.collection
  const productColor = pageContext.color
  const productHandle = pageContext.handle

  // const [variant, setVariant] = useState({ ...initialVariant })
  // const [quantity, setQuantity] = useState(1)

  const {
    addVariantToCart,
    store: { adding },
  } = useContext(StoreContext)

  // const context =useContext(StoreContext)
  // console.log(context)

  const [variantMainImage, setVariantMainImage] = useState({})
  const [firstIndex, setFirstIndex] = useState(null)
  const [lastIndex, setLastIndex] = useState(null)

  useEffect(() => {
    const getAndSetColorMainImage = () => {
      return productData.options.forEach(option => {
        // get all variant colors
        if (option.name === "Color") {
          option.values.forEach(color => {
            productData.variants.forEach(variant => {
              variant.selectedOptions.forEach(selectedOption => {
                //log all main images per variant color
                if (selectedOption.value === color) {
                  if (!variantMainImage.hasOwnProperty(color)) {
                    setVariantMainImage(variantMainImage => ({
                      ...variantMainImage,
                      [color]: variant.image.localFile.relativePath,
                    }))
                  }
                }
              })
            })
          })
        }
      })
    }

    const initializeVariantIndeces = () => {
      productData.images.forEach((image, i) => {
        if (image.localFile.relativePath === variantMainImage[productColor]) {
          setFirstIndex(i)
        }
      })

      for (let j = 0; j < productData.images.length; j++) {
        if (j > firstIndex) {
          // console.log(
          //   firstIndex,
          //   j,
          //   Object.values(variantMainImage).indexOf(
          //     productData.images[j].originalSrc
          //   )
          // )
          // console.log(variantMainImage.Cloud)
          // console.log("os",productData.images[j].localFile.relativePath)
          // console.log("boi",j, productData.images[j].localFile.relativePath === variantMainImage.Cloud)
          if (
            Object.values(variantMainImage).indexOf(
              productData.images[j].localFile.relativePath
            ) > -1
          ) {
            setLastIndex(j - 1)
            break
          } else if (j === productData.images.length - 1) {
            setLastIndex(productData.images.length - 1)
          }
        }
      }
    }

    getAndSetColorMainImage()
    initializeVariantIndeces()
    // console.log(variantMainImage)
    // console.log("indeces",firstIndex, lastIndex)
  }, [
    variantMainImage,
    firstIndex,
    lastIndex,
    productData.images,
    productData.options,
    productData.variants,
    productColor,
  ])

  const [selectionCompareAtPrice, setselectionCompareAtPrice] = useState(null)
  const [selectionPrice, setSelectionPrice] = useState(null)
  const [selectedSize, setSelectedSize] = useState(null)

  useEffect(() => {
    const setInitialSelections = () => {
      for (let i = 0; i < productData.variants.length; i++) {
        // if (productData.variants[i].availableForSale) {
        for (
          let j = 0;
          j < productData.variants[i].selectedOptions.length;
          j++
        ) {
          if (
            productData.variants[i].selectedOptions[j].value === productColor
          ) {
            for (
              let k = 0;
              k < productData.variants[i].selectedOptions.length;
              k++
            ) {
              if (productData.variants[i].selectedOptions[k].name === "Size") {
                // setSelectedSize(
                //   productData.variants[i].selectedOptions[k].value
                // )
                setSelectionPrice(productData.variants[i].price)
                setselectionCompareAtPrice(
                  productData.variants[i].compareAtPrice
                )
                break
              }
            }
          }
        }
        // }
      }
    }

    setInitialSelections()
    // console.log(selectionPrice)
    // console.log(selectedSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [sizes, setSizes] = useState([])
  const [colors, setColors] = useState([])

  useEffect(() => {
    const initializeSizes = () => {
      return productData.options.forEach(option => {
        if (option.name === "Size") {
          return option.values.forEach(size => {
            setSizes(sizes => [...sizes, size])
          })
        }
      })
    }

    // const initializeColors = () => {
    //   return productData.options.forEach(option => {
    //     if (option.name === "Color") {
    //       return option.values.forEach(color => {
    //         setColors(colors => [...colors, color])
    //       })
    //     }
    //   })
    // }

    let colorImages = []

    const updateArray = (arr, color, value) => {
      let isFound = false
      arr = arr.map(function (item) {
        if (item[0] === color) {
          isFound = true
          item[1] = value
        }
        return item
      })
      if (!isFound) {
        arr.push([color, value])
      }
      return arr
    }

    const getAndSetColorMainImage = () => {
      productData.options.forEach(option => {
        // get all variant colors
        if (option.name === "Color") {
          option.values.forEach(color => {
            productData.variants.forEach(variant => {
              variant.selectedOptions.forEach(selectedOption => {
                //log all main images per variant color
                if (selectedOption.value === color) {
                  productData.images.forEach((image, i) => {
                    if (
                      image.localFile.relativePath ===
                      variant.image.localFile.relativePath
                    ) {
                      colorImages = updateArray(colorImages, color, i)
                    }
                  })
                }
              })
            })
          })
        }
      })
    }

    initializeSizes()
    // initializeColors()
    getAndSetColorMainImage()
    setColors(colorImages)
  }, [productData.options, productData.images, productData.variants])

  const [thumbsScroll, setThumbsScroll] = useState([])

  const [viewWidth, setViewWidth] = useState(null)

  useEffect(() => {
    setViewWidth(document.documentElement.clientWidth)
  }, [])

  // let viewWidth = document.documentElement.clientWidth

  const thumbsScrollProps = useSpring({
    transform: `translateY(${-(thumbsScroll * (viewWidth * 0.12 + 40))}px)`,
    // transform: `translateY(${thumbsScroll * -254.55}px)`,
  })

  const ThumbsScrollAnime = animated.div

  const onImageChange = (i, isVisible) => {
    if (isVisible) {
      setThumbsScroll(i - firstIndex)
    }
  }

  const handleThumbUp = () => {
    if (thumbsScroll > 0) {
      setThumbsScroll(thumbsScroll - 1)
    }
  }

  const handleThumbDown = () => {
    if (thumbsScroll < lastIndex - firstIndex) {
      setThumbsScroll(thumbsScroll + 1)
    }
  }

  const [variantId, setVariantId] = useState(null)

  useEffect(() => {
    productData.variants.forEach(variant => {
      let isVariantColor = false
      let isVariantSize = false
      variant.selectedOptions.forEach(option => {
        if (option.name === "Size" && option.value === selectedSize) {
          isVariantSize = true
        } else if (option.name === "Color" && option.value === productColor) {
          isVariantColor = true
        }

        if (isVariantColor && isVariantSize) {
          setVariantId(variant.shopifyId)
        }
      })
    })
  }, [selectedSize, productColor, productData])

  const [available, setAvailable]= useState(true)

  useEffect(() => {
    productData.variants.filter(variant => variant.shopifyId === variantId).forEach(variant => {
      setAvailable(variant.availableForSale)
    })
  },[variantId, productData.variants])

  const [cartErrorMsg, setCartErrorMsg] = useState(null)

  const handleAddToCart = () => {
    if (variantId) {
      addVariantToCart(variantId, 1)

      //google analytics
      trackCustomEvent({
        // string - required - The object that was interacted with (e.g.video)
        category: `${productHandle}-${productColor}`,
        // string - required - Type of interaction (e.g. 'play')
        action: "Click",
        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
        label: "Add to Cart Product Page",
        // number - optional - Numeric value associated with the event. (e.g. A product ID)
        value: variantId,
      })

      //pixel analytics
      fbTrack("track", "AddToCart")
    } else {
      setCartErrorMsg("Please Select a Size")
    }
  }

  const renderImages = () => {
    return productData.images.map((image, i) => {
      if (i >= firstIndex && i <= lastIndex) {
        return (
          <VisibilitySensor
            partialVisibility={true}
            offset={{ top: 447, bottom: 450 }}
            onChange={isVisible => onImageChange(i, isVisible)}
            key={image.localFile.relativePath}
          >
            <div
              id={`${i} product image`}
              className={productPageStyles.imageContainer}
            >
              <Img
                fluid={image.localFile.childImageSharp.fluid}
                alt={`${productData.title}`}
              />
            </div>
          </VisibilitySensor>
        )
      }
      return null
    })
  }

  const renderThumbs = () => {
    return productData.images.map((image, i) => {
      if (i >= firstIndex && i <= lastIndex) {
        return (
          <ScrollLink
            to={`${i} product image`}
            smooth={true}
            duration={500}
            offset={-60}
            key={image.localFile.relativePath}
          >
            <div
              style={{ opacity: thumbsScroll === i - firstIndex ? 1 : 0.5 }}
              className={productPageStyles.thumb}
              // role="button"
              // tabIndex="0"
              // onKeyDown={() => handleThumbClick(i)}
              // onClick={() => handleThumbClick(i)}
            >
              <Img
                fluid={image.localFile.childImageSharp.fluid}
                alt={`${productData.title} thumb`}
              />
            </div>
          </ScrollLink>
        )
      }
      return null
    })
  }

  const renderSizes = () => {
    return sizes.map(size => {
      return (
        <button
          onClick={() => setSelectedSize(size)}
          className={productPageStyles.sizeButton}
          key={`${size} button`}
          style={{
            backgroundColor: size === selectedSize ? "#70116b" : null,
            color: size === selectedSize ? "white" : null,
          }}
        >
          <span className={productPageStyles.sizeSpan}>{size}</span>
        </button>
      )
    })
  }

  const renderColors = () => {
    return colors.map((colorArr, i) => {
      if (i === 0) {
        return (
          <Link
            className={productPageStyles.colorButton}
            key={`${colorArr[0]} button`}
            to={`/${productCollection}/${productHandle}-${colorArr[0]}`
              .replace(/ /g, "-")
              .toLowerCase()}
            style={{
              border:
                productColor === colorArr[0]
                  ? "2px solid black"
                  : "2px solid #b4b4b4",
            }}
          >
            <div className={productPageStyles.colorBox}>
              <Img
                alt={colorArr[0]}
                fixed={
                  colors[1]
                    ? productData.images[colors[1][1] - 1].localFile
                        .childImageSharp.fixed
                    : productData.images[productData.images.length - 1]
                        .localFile.childImageSharp.fixed
                }
              />
            </div>
          </Link>
        )
      } else if (i === colors.length - 1) {
        return (
          <Link
            className={productPageStyles.colorButton}
            key={`${colorArr[0]} button`}
            to={`/${productCollection}/${productHandle}-${colorArr[0]}`
              .replace(/ /g, "-")
              .toLowerCase()}
            style={{
              border:
                productColor === colorArr[0]
                  ? "2px solid black"
                  : "2px solid #b4b4b4",
            }}
          >
            <div className={productPageStyles.colorBox}>
              <Img
                alt={colorArr[0]}
                fixed={
                  productData.images[productData.images.length - 1].localFile
                    .childImageSharp.fixed
                }
              />
            </div>
          </Link>
        )
      } else {
        return (
          <Link
            className={productPageStyles.colorButton}
            key={`${colorArr[0]} button`}
            to={`/${productCollection}/${productHandle}-${colorArr[0]}`
              .replace(/ /g, "-")
              .toLowerCase()}
            style={{
              border:
                productColor === colorArr[0]
                  ? "2px solid black"
                  : "2px solid #b4b4b4",
            }}
          >
            <div className={productPageStyles.colorBox}>
              <Img
                alt={colorArr[0]}
                fixed={
                  productData.images[colors[i + 1][1] - 1].localFile
                    .childImageSharp.fixed
                }
              />
            </div>
          </Link>
        )
      }
    })
  }

  //handle size guide
  const [openSizeGuide, setOpenSizeGuide] = useState(false)

  //handle swiper for mobile
  SwiperCore.use([Navigation, Pagination, Thumbs])

  // store thumbs swiper instance
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  const renderSwiperSlides = () => {
    return productData.images.map((image, i) => {
      if (i >= firstIndex && i <= lastIndex) {
        return (
          <SwiperSlide key={`Swiper-slide-${i}`}>
            <div className={productPageStyles.swiperSlideImage}>
              <Img
                fluid={image.localFile.childImageSharp.fluid}
                alt={`${productData.title}`}
              />
            </div>
          </SwiperSlide>
        )
      } else {
        return null
      }
    })
  }

  const renderSwiperThumbs = () => {
    return productData.images.map((image, i) => {
      if (i >= firstIndex && i <= lastIndex) {
        return (
          <SwiperSlide key={`Swiper-slide-${i}`}>
            <div className={productPageStyles.swiperThumbImage}>
              <Img
                fluid={image.localFile.childImageSharp.fluid}
                alt={`${productData.title}`}
              />
            </div>
          </SwiperSlide>
        )
      } else {
        return null
      }
    })
  }

  return (
    <Layout>
      <HELM
        title="Product"
        keywords={[
          ...productData.tags,
          `${productData.title}`,
          `${productData.productType}`,
          `Women`,
          `Teens`,
        ]}
      />

      <div className={productPageStyles.productOverview}>
        {openSizeGuide ? (
          <SizeGuide
            handleClose={() => setOpenSizeGuide(false)}
            collection={productCollection}
          />
        ) : null}

        <div className={productPageStyles.mobileSwiper}>
          <Swiper
            id="swiper-color"
            thumbs={{ swiper: thumbsSwiper }}
            loop={false}
            navigation
            pagination={{ type: "bullets" }}
          >
            {renderSwiperSlides()}
          </Swiper>
        </div>

        <div className={productPageStyles.mobileSwiperThumbs}>
          <Swiper
            slidesPerView={4}
            spaceBetween={5}
            onSwiper={setThumbsSwiper}
            watchSlidesVisibility
            watchSlidesProgress
          >
            {renderSwiperThumbs()}
          </Swiper>
        </div>

        <div className={productPageStyles.thumbSlider}>
          <div
            className={productPageStyles.thumbControllerUp}
            role="button"
            tabIndex="0"
            onKeyDown={handleThumbUp}
            onClick={handleThumbUp}
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </div>
          <div className={productPageStyles.thumbsContainer}>
            <ThumbsScrollAnime
              style={thumbsScrollProps}
              className={productPageStyles.thumbsScroll}
            >
              {renderThumbs()}
            </ThumbsScrollAnime>
          </div>
          <div
            className={productPageStyles.thumbControllerDown}
            role="button"
            tabIndex="0"
            onKeyDown={handleThumbDown}
            onClick={handleThumbDown}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
        <div className={productPageStyles.scrollContainer}>
          {renderImages()}
        </div>

        <div className={productPageStyles.productDetailsBox}>
          <div className={productPageStyles.productDetails}>
            <div className={productPageStyles.detailsContainer}>
              {selectionCompareAtPrice > selectionPrice ? (
                <span
                  className={productPageStyles.compareatprice}
                >{`₱${selectionCompareAtPrice}`}</span>
              ) : null}
              <span
                className={productPageStyles.price}
              >{`₱${selectionPrice}`}</span>
              <h2 className={productPageStyles.titleLf}>Lilly Feather</h2>
              <h1
                className={productPageStyles.title}
              >{`${productData.title} - ${productColor}`}</h1>
              <div className={productPageStyles.colorSelectionBox}>
                <h4 className={productPageStyles.colorsTitle}>Color:</h4>
                <span className={productPageStyles.selectedColor}>
                  {productColor}
                </span>
              </div>
              <div className={productPageStyles.colors}>{renderColors()}</div>
              <div className={productPageStyles.sizeBox}>
                <h4 className={productPageStyles.sizesTitle}>Size:</h4>
                <span className={productPageStyles.selectedSize}>
                  {selectedSize ? selectedSize : ""}
                </span>
              </div>
              <div className={productPageStyles.sizes}>{renderSizes()}</div>
              {available ? null : <span className={productPageStyles.soldOut}>This item is sold out</span>}
              <button
                className={productPageStyles.sizeGuide}
                onClick={() => setOpenSizeGuide(true)}
              >
                Size Guide
              </button>
              {cartErrorMsg ? (
                <span className={productPageStyles.cartErrorMsg}>
                  {cartErrorMsg}
                </span>
              ) : null}
              <button
                className={productPageStyles.addToBag}
                onClick={handleAddToCart}
              >
                {adding ? (
                  <BounceLoader
                    className={productPageStyles.spinner}
                    size={50}
                    color={"white"}
                    loading={true}
                  />
                ) : (
                  <span className={productPageStyles.addToBagSpan}>
                    Add to Bag
                  </span>
                )}
              </button>
              <div className={productPageStyles.dropdowns}>
                <DropDown title={"Details"}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(productData.descriptionHtml),
                      // __html: DOMpurify.sanitize(productData.descriptionHtml)
                      //   .replace(/<p/g, "<div")
                      //   .replace(/<\/p/g, "</div"),
                    }}
                  />
                </DropDown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={productPageStyles.detailFeatureSeparatorContainer}>
        <div className={productPageStyles.detailFeatureSeparator} />
      </div>

      <FeaturedCatalog collection={productCollection} />
    </Layout>
  )
}

export default ProductPage
